/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___3q6NB {
  padding: 0.75rem;
  display: flex;
  border-top: 0.0625rem solid #ebebeb;
  background-color: #fff;
  font-weight: 500;
}

.content___2vkNe {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  color: #949494;
  margin: 0 auto;
}

.content___2vkNe strong {
  font-family: "Dancing Script", sans-serif;
  margin-left: 0.375rem;
  font-size: 1.25rem;
  letter-spacing: 0.1875rem;
}

.content___2vkNe div:nth-of-type(1) {
  flex-grow: 1;
}

@media (min-width: 1400px) {
  .content___2vkNe {
    max-width: 1400px;
  }
}

@media (max-width: 480px) {
  .content___2vkNe {
    justify-content: center;
  }

  .content___2vkNe div:nth-of-type(1) {
    flex-grow: 0;
  }
}
