/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.banner___DRhke {
  height: 3.3125rem;
  background-color: #f6f7f9;
}

.container___GA4k0 {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
}
