/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.menu___3txzc .anticon {
  margin-right: 8px;
}

.menu___3txzc .ant-dropdown-menu-item {
  min-width: 160px;
}

.dropDown___2lCuA {
  line-height: 64px;
  vertical-align: top;
  cursor: pointer;
}

.dropDown___2lCuA > span {
  font-size: 16px !important;
  transform: none !important;
}

.dropDown___2lCuA > span svg {
  position: relative;
  top: -1px;
}
