/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.wrapper___1vzb0 {
  background-color: #ffffff;
}

.container___1eY-R {
  max-width: 1400px;
  margin: 0 auto;
  height: 4rem;
  padding: 0 1rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  display: flex;
}

.container___1eY-R ul {
  border-bottom: unset;
}

.container___1eY-R ul li {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.container___1eY-R .ant-menu-horizontal {
  line-height: 4rem;
}

.logo___BypJn {
  margin-right: 0.25rem;
}

.logo___BypJn img {
  height: 2rem;
  width: 3.0625rem;
}

.logoText___1rhry {
  letter-spacing: 0.2rem;
  color: #c2185b;
  font-weight: 700;
  font-family: "Dancing Script";
  font-size: 2rem !important;
}

@media (max-width: 57.5rem) {
  .logoText___1rhry {
    display: none;
  }
}

.logoContainer___2jJ0b {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}

.menuContainer___18NQh {
  flex: 1 1 0%;
}

@media (max-width: 57.5rem) {
  .menuContainer___18NQh {
    display: none;
  }
}

.rightContent___Hf_PK {
  margin-left: auto;
}

.menuBar___1EKmQ {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}

@media (min-width: 57.5rem) {
  .menuBar___1EKmQ {
    display: none;
  }
}

.drawerContainer___2fXct .ant-drawer-body {
  padding: 0.25rem;
}

.drawerContainer___2fXct .ant-menu.ant-menu-inline {
  border-right: unset;
}
