/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.menu___2I-Yb .anticon {
  margin-right: 8px;
}

.menu___2I-Yb .ant-dropdown-menu-item {
  min-width: 160px;
}

.right___SJ-ol {
  display: flex;
  float: right;
  height: 64px;
  margin-left: auto;
  overflow: hidden;
}

.right___SJ-ol .action___1FGwn {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.right___SJ-ol .action___1FGwn > span {
  color: #333;
  vertical-align: middle;
}

.right___SJ-ol .action___1FGwn:hover {
  background: rgba(0, 0, 0, 0.025);
}

.right___SJ-ol .action___1FGwn.opened {
  background: rgba(0, 0, 0, 0.025);
}

.right___SJ-ol .search___4ScAK {
  padding: 0 12px;
}

.right___SJ-ol .search___4ScAK:hover {
  background: transparent;
}

.right___SJ-ol .account___2BNhM .avatar___3CsCk {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 1rem;
  color: #c2185b;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}

.dark___3__hK .action___1FGwn {
  color: rgba(255, 255, 255, 0.85);
}

.dark___3__hK .action___1FGwn > span {
  color: rgba(255, 255, 255, 0.85);
}

.dark___3__hK .action___1FGwn:hover,
.dark___3__hK .action___1FGwn.opened {
  background: #c2185b;
}

.ant-pro-global-header .dark___3__hK .action___1FGwn {
  color: #333;
}

.ant-pro-global-header .dark___3__hK .action___1FGwn > span {
  color: #333;
}

.ant-pro-global-header .dark___3__hK .action___1FGwn:hover {
  color: rgba(255, 255, 255, 0.85);
}

.ant-pro-global-header .dark___3__hK .action___1FGwn:hover > span {
  color: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 768px) {
  .ant-divider-vertical {
    vertical-align: unset;
  }

  .name___2cmoF {
    display: none;
  }

  .right___SJ-ol .account___2BNhM .avatar___3CsCk {
    margin-right: 0;
  }

  .right___SJ-ol .search___4ScAK {
    display: none;
  }
}
